import React from 'react';

const DynamicRoutes = ({ component: Component, location, ...rest }) => {
  //   For now this sever for one route only.
  //   if (location.pathname === '/confirm/email') { ... }
  return (
    <Component
      {...{
        location,
        ...rest,
      }}
    />
  );
};

export default DynamicRoutes;
