import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SocialShareButtons from './SocialShareButtons';
import { EmailConfirmedIcon, HourGlassIcon, ErrorIcon } from '../img/icons';
import Subscribe from './Subscribe';
import { getUrlParams } from '../utils';

const resultMap = {
  success: 'success',
  error: 'error',
};

const Success = () => (
  <div>
    <EmailConfirmedIcon className="inline-block fill-current h-12 w-12 mb-5 text-disabled-on-surface-0" />
    <p className="mb-4">Email confirmed! Thank you!</p>
    <SocialShareButtons />
  </div>
);

const Error = () => (
  <div className="px-6">
    <ErrorIcon className="inline-block fill-current h-12 w-12 mb-5 text-disabled-on-surface-0" />
    <p className="mb-8">
      The token is not valid. <br />
      Please, try again using the form below
    </p>
    <Subscribe />
  </div>
);

const Loading = () => (
  <div>
    <HourGlassIcon className="inline-block fill-current h-12 w-12 mb-5 text-disabled-on-surface-0" />
    <p>Checking the token ...</p>
  </div>
);

const EmailConfirmation = ({ location }) => {
  const { token } = getUrlParams(location.search);
  const [result, setResult] = useState(false);

  useEffect(() => {
    axios
      .get(`${process.env.API_URL || ''}/api/confirm/email?token=${token}`)
      .then(function() {
        setResult(resultMap.success);
      })
      .catch(function() {
        setResult(resultMap.error);
      });
  });

  return (
    <div className="flex justify-center">
      <div className="md:max-w-xl text-center mt-8">
        {result === resultMap.success ? (
          <Success />
        ) : result === resultMap.error ? (
          <Error />
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default EmailConfirmation;
