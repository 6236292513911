import React from 'react';
import { Router } from '@reach/router';
import Layout from '../components/Layout';
import DynamicRoutes from '../components/DynamicRoutes';
import EmailConfirmation from '../components/EmailConfirmation';

const DynamicRouteWrapper = () => {
  return (
    <Layout>
      <Router basepath="/confirm">
        <DynamicRoutes path="/email" component={EmailConfirmation} />
        <DynamicRoutes path="/*" component={EmailConfirmation} />
      </Router>
    </Layout>
  );
};

export default DynamicRouteWrapper;
